<template>
	<div class="philosophy">
		<Navigation
			:collapsible="windowWidth <= 1200"
		/>
		<div class="philosophy__content-container">
			<div class="philosophy__content">
				<h1>Philosophy</h1>
				<h2 id="tl;dr">TL;DR</h2>
				<p>This list is subjective. If your tastes align with mine, you will find this very agreeable, and if not, recoil in horror of my palate. Even so, I still believe this to be a worthwhile reference.</p>
				<p>My evaluation tends to be almost exclusively based on taste. The price will only affect the score if it deviates greatly from the food quality (which will also be noted in the review). The portion sizes and sides are noted but do not affect the score.</p>
				<p>Bon appétit!</p>
				<h2 id="how-to-use-this-resource">How to Use This Resource</h2>
				<p>To those of you still reading past the TL;DR, know that your effort is appreciated. I consider you fundamentally better people to the rest of the losers.</p>
				<p>Let me once again stress that this list is entirely subjective. I briefly considered a more neutral approach but I ultimately abandoned it. I believe people's tastes are too varied and food is simply too personal to try and work around it.</p>
				<p>Even so, I still believe this to be a worthwhile reference for most people. You just have to give in and adopt my tastes exactly. Or you could recalibrate my reviews to match your biases I guess.</p>
				<p>There are some things I try to do to help with the latter:</p>
				<p>
					<ol style="font-size: 95%;">
						<li>Each burger's ingredients are listed in the <em>Config</em> section. I did not do this from the start so there may be a few burgers in this list where the section's missing but for the most part it's present.</li>
						<li>There are inevitably going to be some ingredients I can't properly score. Like jalapeños, for example. I don't like them. Never have. That also means I can't properly convey the difference between a good and a bad one. They're all unpalatable to me.<br />So: what do? Well, in case I order a burger with ingredients like this, I will make a note of it in the review and tell you roughly how big of an impact their inclusion in the burger had on my score.</li>
						<li>Since I probably tried more burgers than you (not trying to flex here but statistically speaking that's most likely the case), find the burgers we've both tried and see whether you agree with my assessment of them.</li>
					</ol>
				</p>
				<p>Taking all that into consideration, you'll eventually be able to recognize and understand my preferences over time and discover where we agree and where we diverge. Based on that you can get a rough idea of which other burgers from my list you may like or dislike.</p>
				<h2 id="evaluation">Evaluation</h2>
				<p>Unlike some "competitors" out there, I don't fuck around. I review and score burgers based on taste alone.</p>
				<p>This has undoubtedly raised some eyebrows. "What else would he be scoring," you may be asking yourselves, bewilderingly. If this was indeed your initial reaction, you've come to the right place. I won't name any names but I shit you not, I've seen reviews out there from people who factor completely unrelated things like lunchroom's ambiance or slight perceived rudeness of waiters <em>in their burger scores</em>. You won't get any of that nonsense here.</p>
				<p>The levels of tomfoolery an establishment would need to stoop to for me to even mention it would have to be truly extreme. If I ordered a burger at a restaurant that turned out to be a literal meth lab which the police decided to raid in the middle of my meal and during the commotion a waiter accidentally shot me in the knee - but the burger was otherwise solid - I would at most write an off-handed comment about the ordeal at the end of my review. But the score would remain unaffected.</p>
				<p>Back to the matter at hand. My burger evaluation calculation is based on the following (in order of importance):</p>
				<p>
					<ul style="font-size: 95%;">
						<li><strong>Ingredients.</strong> Their selection, their individual freshness and quality, and their cohesive harmoniousness.</li>
						<li><strong>Portion size.</strong> Relevant but plays a very minor role. It will influence the score only if well below or above average.</li>
						<li><strong>Price/Performance Ratio.</strong><span class="new">NEW</span></li>
					</ul>
				</p>
				<p><em>A few notes on the price: until recently I completely disregarded burgers' prices in my scores. Broadly speaking, there were not that many outliers. Some quite expensive burgers existed but they usually earned their price tag. In cases like those I usually made a (snarky) note of it in my review but left the score intact.<br />Recently, however, some new joints have been popping up that charge absurd premiums for average or even below average food. And since there is now a decent number of them out there I can no longer maintain the status quo.<br />The new policy is this: given an average portion size, if the price is either well below or above average, this will now be made note of and the score will change. No more free rides!</em></p>
				<h2 id="scores">Scores</h2>
				<p>This is what the main scores translate to in practice, roughly speaking:</p>
				<div class="philosophy__scores-wrapper">
					<div class="philosophy__scores-icons">
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
					</div>
					<div class="philosophy__scores-description">As good as it gets. Every ingredient is of the highest quality and they are sensibly chosen, achieving perfect harmony. Worth the blind.</div>
				</div>
				<div class="philosophy__scores-wrapper">
					<div class="philosophy__scores-icons">
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
						<img class="philosophy_glyph-score empty" src="../../assets/Images/Icons/emptyBurger.png" />
					</div>
					<div class="philosophy__scores-description">Close to perfect but with minor flaws. Either the balance is ever so slightly off, be it due to quality control issues or a rogue additive that doesn't fit.</div>
				</div>
				<div class="philosophy__scores-wrapper">
					<div class="philosophy__scores-icons">
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
						<img class="philosophy_glyph-score empty" src="../../assets/Images/Icons/emptyBurger.png" />
						<img class="philosophy_glyph-score empty" src="../../assets/Images/Icons/emptyBurger.png" />
					</div>
					<div class="philosophy__scores-description">There is a serious flaw somewhere or a series of minor flaws. The balance could be quite off, the ingredients may be of average quality, there may be one or more ingredients that don't fit. Even so, a burger like that is still above average, in most cases worth buying.</div>
				</div>
				<div class="philosophy__scores-wrapper">
					<div class="philosophy__scores-icons">
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
						<img class="philosophy_glyph-score empty" src="../../assets/Images/Icons/emptyBurger.png" />
						<img class="philosophy_glyph-score empty" src="../../assets/Images/Icons/emptyBurger.png" />
						<img class="philosophy_glyph-score empty" src="../../assets/Images/Icons/emptyBurger.png" />
					</div>
					<div class="philosophy__scores-description">There is a deal-breaking flaw or a number of serious flaws. Either the balance is basically fucked, there are serious quality control issues, the ingredients chosen are generally of lower quality, or the choice of ingredients might be downright nonsensical. It's usually still edible but I would basically never recommend it.</div>
				</div>
				<div class="philosophy__scores-wrapper">
					<div class="philosophy__scores-icons">
						<img class="philosophy_glyph-score full" src="../../assets/Images/Icons/fullBurger.png" />
						<img class="philosophy_glyph-score empty" src="../../assets/Images/Icons/emptyBurger.png" />
						<img class="philosophy_glyph-score empty" src="../../assets/Images/Icons/emptyBurger.png" />
						<img class="philosophy_glyph-score empty" src="../../assets/Images/Icons/emptyBurger.png" />
						<img class="philosophy_glyph-score empty" src="../../assets/Images/Icons/emptyBurger.png" />
					</div>
					<div class="philosophy__scores-description">An abomination beyond salvation. An affront to the gods.</div>
				</div>
				<p style="margin-top: 1em;">(I skipped the in-betweener ½ point scores because I'm lazy. I hope you're smart enough to fill in the blanks yourself.)</p>
				<h2 id="disclosure">Disclosure</h2>
				<p>None of the photos of burgers on this list were taken by me (subject to change). I simply tried to find the best images of those burgers in whatever way I could (shout-out to Wolt for greatly simplifying this process).</p>
				<p>Rest assured that no one is paying, asking for, or otherwise influencing my reviews. All thoughts and opinions are my own.</p>
				<p>Which is very disappointing, frankly. I've been waiting for years for someone to get in touch so we could work something out. If you are interested, please contact me to learn more about my fee structure.</p>
			</div>
			<Footer/>
		</div>
	</div>
</template>

<script>
import Navigation from "./Components/Navigation.vue"
import ScrollToChapter from "@/mixins/ScrollToChapter"
import Footer from "../../components/Footer.vue"

export default {
	components: {
		Navigation,
		Footer

	},
	mixins: [
		ScrollToChapter
	],
	data () {
		return {
			windowWidth: window.innerWidth
		}
	},
	mounted () {
		window.onresize = () => {
			this.windowWidth = window.innerWidth
		}
	}
}
</script>

<style lang="scss">
	.philosophy {
		background: #FAF1E4;
		display: flex;
		flex-direction: row;

		@media only screen and (max-width: 1200px) {
			flex-direction: column;
		}

		.philosophy__content-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.philosophy__content {
				padding: 64px 30px;
				font-family: "Lato", sans-serif;
				font-size: 17px;
				line-height: 1.8;
				font-style: normal;
				color: #000;
				font-weight: 400;
				max-width: 620px;
				position: relative;

				@media only screen and (max-width: 1200px) {
					margin: 0 auto;
				}

				@media only screen and (max-width: 767px) {
					padding: 30px 30px 50px;
					max-width: 500px;
				}
			}

			h1 {
				color: #000;
				font-size: 38px;
				font-family: "Open Sans", sans-serif;
				font-weight: 700;
				letter-spacing: 1px;
				line-height: 1.4;
				margin-bottom: 40px;
				margin-top: 20px;
				text-align: center;
				text-transform: uppercase;

				@media only screen and (max-width: 820px) {
					margin-top: 40px 0;
				}

			}

			h2 {
				margin-top: 46px;
				margin-bottom: 20px;

				@media only screen and (max-width: 820px) {
					&:first-of-type {
						margin-top: 0;
					}
				}
			}

			li {
				margin-bottom: 6px;
			}

			li .new {
				border: 1px solid #444;
				border-radius: 3px;
				display: inline-block;
				font-weight: 600;
				font-size: 10px;
				padding: 0px 5px;
				margin: 5px 5px 0 5px;
				vertical-align: top;

				@media only screen and (max-width: 1200px) and (min-width: 767px) {
					font-size: 9px;
					margin: 4px 5px 0 5px;
				}

				@media only screen and (max-width: 767px) {
					font-size: 9px;
					margin: 4px 5px 0 5px;
				}
			}

			.philosophy__scores-wrapper {
				display: block;
				padding-left: 30px;

				.philosophy__scores-icons,
				.philosophy__scores-description {
					display: block;
				}

				.philosophy__scores-icons {
					white-space: nowrap;
					margin-top: 1em;

					.philosophy_glyph-score {
						height: 22px;
						margin-top: 6px;
						margin-right: 2px;

						@media only screen and (max-width: 767px) {
							margin-top: 0;
						}
					}

					.empty {
						opacity: 0.5;
					}
				}

				.philosophy__scores-description {
					padding-left: 1px;
				}
			}
		}
	}
</style>
