export default {
	watch: {
		"$route.hash": {
			handler (value) {
				this.$nextTick(() => {
					const navigationAnchor = document.getElementById(value.substring(1, value.length))
					if (navigationAnchor) {
						const anchorCoordinates = navigationAnchor.getBoundingClientRect()
						window.scrollTo(anchorCoordinates.x, anchorCoordinates.y - 100)
					}
				})
			}
		}
	},
	mounted () {
		window.onresize = () => {
			this.windowWidth = window.innerWidth
		}
		if (this.$route.hash) {
			this.$nextTick(() => {
				const navigationAnchor = document.getElementById(this.$route.hash.substring(1, this.$route.hash.length))
				if (navigationAnchor) {
					const anchorCoordinates = navigationAnchor.getBoundingClientRect()
					window.scrollTo(anchorCoordinates.x, anchorCoordinates.y - 100)
				}
			})
		}
	}
}
