<template>
	<div class="philosophy-navigation">
		<button
			v-if="collapsible"
			class="philosophy-navigation__open-button"
			@click="toggleLinks"
		>
			<span>Chapters</span>
			<Icon
				v-if="linksOpen"
				class="philosophy-navigation__icon"
				:icon="['fas', 'chevron-up']"
			/>
			<Icon
				v-else
				class="philosophy-navigation__icon"
				:icon="['fas', 'chevron-down']"
			/>
		</button>
		<UikCollapse ref="collapse">
			<div v-if="linksOpen" class="philosophy-navigation__links-container">
				<div v-if="!collapsible" class="philosophy-navigation__links-container__title">Chapters</div>
				<div class="philosophy-navigation__links-container__content">
					<router-link
						v-for="link in $options.links"
						:key="link.path"
						:to="link.path"
						@click.native="collapsed = true"
					>{{ link.title }}</router-link>
				</div>
			</div>
		</UikCollapse>
	</div>
</template>

<script>
import UikCollapse from "@/components/UikCollapse.vue"

export default {
	links: [
		{
			path: "/philosophy#tl;dr",
			title: "TL;DR"
		},
		{
			path: "/philosophy#how-to-use-this-resource",
			title: "How To Use This Resource"
		},
		{
			path: "/philosophy#evaluation",
			title: "Evaluation"
		},
		{
			path: "/philosophy#scores",
			title: "Scores"
		},
		{
			path: "/philosophy#disclosure",
			title: "Disclosure"
		}
	],
	components: {
		UikCollapse
	},
	props: {
		collapsible: { type: Boolean, default: false }
	},
	beforeRouteEnter () {
		this.collapsed = true
	},
	data () {
		return {
			collapsed: true
		}
	},
	computed: {
		activeLink () {
			return this.$options.links.find(link => link.path === this.$route.path)
		},
		linksOpen () {
			if (!this.collapsible) return true
			return !this.collapsed
		}
	},
	watch: {
		collapsible () {
			this.collapsed = true
		}
	},
	methods: {
		toggleLinks () {
			this.collapsed = !this.collapsed
		}
	}
}
</script>

<style lang="scss">
	.philosophy-navigation {
		display: flex;
		flex-direction: column;
		width: 420px;
		min-width: 170px;
		position: sticky;
		padding: 10px;
		z-index: 1;
		background: #FEFEFE;
		overflow-x: hidden;
		line-height: 22px;
		height: calc(100vh - 86px);
		top: 86px;
		box-sizing: border-box;
		box-shadow: 0 0 13px #0000001f;

		@media only screen and (max-width: 1200px) {
			display: block;
			padding: 0;
			margin: 0 auto;
			margin-top: 40px;
			height: inherit;
			position: unset;
			border-radius: 5px;
			box-shadow: 0 0 13px #0000001f;
		}

		@media only screen and (max-width: 767px) {
			display: block;
			margin: 0 auto;
			height: inherit;
			position: unset;
			border-radius: 0;
			width: 100%;
			min-width: 100%;
			padding: 0;
			box-shadow: 0 0 13px #0000001f;
			background: #FEFEFE;
		}

		a {
			color: #000;
			background: #FEFEFE;
			font-style: italic;
			padding: 8px 25px;

			&:hover {
				background-color: #F5BB8A;
				border-radius: 5px;
			}

			@media only screen and (max-width: 1200px) {
				padding: 12px 25px;

				&:hover {
					border-radius: 0;
				}
			}

			@media only screen and (max-width: 767px) {
				padding: 16px 40px;

				&:hover {
					border-radius: 0;
				}
			}
		}

		.philosophy-navigation__open-button {
			background: #FEFEFE;
			border: none;
			font-size: 15px;
			letter-spacing: 1px;
			padding: 20px;
			text-transform: uppercase;
			width: 100%;
			display: flex;
			cursor: pointer;
			color: #2c3e50;
			font-family: "Nunito Sans", sans-serif;

			&:hover {
				background: #F5BB8A;
				border-radius: 5px 5px 0 0;

				@media only screen and (max-width: 767px) {
					border-radius: 0;
				}
			}

			@media only screen and (max-width: 1200px) {
				font-weight: 600;
				justify-content: space-between;
			}

			@media only screen and (max-width: 767px) {
				padding: 20px 32px;
				height: 80px;
				align-items: center;
			}
		}

		.philosophy-navigation__links-container {
			display: flex;
			flex-direction: column;
			padding-bottom: 8px;

			.philosophy-navigation__links-container__title {
				font-size: 15px;
				text-transform: uppercase;
				padding: 20px 0 20px 20px;
				letter-spacing: 1px;
				font-weight: 600;
				font-family: "Nunito Sans", sans-serif;
			}

			.philosophy-navigation__links-container__content {
				display: contents;
			}
		}
	}
</style>
